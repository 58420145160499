import {
  AnyCreativeReport,
  AnyCreativeReportGroup,
  CreativeReportType,
} from "~/types/AnyCreativeReport";
import { copyTextToClipboard } from "~/shared/utils";
import { Creative } from "~/types/Creative";
import { Provider } from "~/types/shared";

export const useAnyCreativeReport = () => {
  const SEPERATOR = "|||";

  const getReportDetailPage = (report: {
    uuid: string;
    type: CreativeReportType;
  }) => {
    if (report.type === "topcreatives") {
      return "/creative-reporting/detail/" + report.uuid;
    }
    return "/creative-comparison/detail/" + report.uuid;
  };

  const getReportDuplicatePage = (report: {
    id: number;
    type: CreativeReportType;
  }) => {
    if (report.type === "topcreatives") {
      return "/creative-reporting?reportId=" + report.id + "&mode=duplicate";
    }
    return "/creative-comparison?reportId=" + report.id + "&mode=duplicate";
  };

  const getReportKey = (report: AnyCreativeReport) => {
    return report.type + SEPERATOR + report.id;
  };

  const getReportId = (key: string) => {
    return Number(key.split(SEPERATOR)[1]);
  };

  const getReportType = (key: string) => {
    return key.split(SEPERATOR)[0] as CreativeReportType;
  };

  const getReportByKey = (key: string) => {
    const type = key.split(SEPERATOR)[0] as CreativeReportType;
    const id = Number(key.split(SEPERATOR)[1]);
    const store =
      type === "topcreatives"
        ? useCreativeReportingStore()
        : useCreativeComparisonStore();
    return store.getReportById(id);
  };

  const createReport = async (input: {
    type: CreativeReportType;
    clientId: number;
    folderId: number | null;
  }) => {
    const notificationStore = useNotificationStore();
    const store =
      input.type === "topcreatives"
        ? useCreativeReportingStore()
        : useCreativeComparisonStore();

    const reportIdOrError = await store.createReport({
      title: null,
      clientId: input.clientId,
      description: null,
      meta: true,
      tiktok: true,
      youtube: true,
      folderId: input.folderId,
    });

    if (reportIdOrError == null) {
      notificationStore.notify({
        type: "error",
        message: "Something went wrong",
      });
      return;
    }

    if (typeof reportIdOrError === "string") {
      notificationStore.notify({
        type: "error",
        message: reportIdOrError,
      });
      return;
    }

    const { uuid } = reportIdOrError;

    const router = useRouter();
    router.push(getReportDetailPage({ uuid, type: input.type }));
  };

  const copyShareLink = (
    report: {
      uuid: string;
      type: CreativeReportType;
    },
    provider: Provider = Provider.META,
  ) => {
    const config = useRuntimeConfig();
    const { notify } = useNotifications();
    const { track, AnalyticsEvents } = useSegment();
    const slug =
      report.type === "topcreatives"
        ? "creative-reporting"
        : "creative-comparison";
    let shareLink =
      config.public.baseUrl + "/" + slug + "/share/" + report.uuid;
    if (provider != null) {
      shareLink += "?provider=" + provider;
    }
    copyTextToClipboard(shareLink);
    notify(null, "Link copied");
    track(
      report.type === "topcreatives"
        ? AnalyticsEvents.SHARE_TOPCREATIVE_REPORT
        : AnalyticsEvents.SHARE_COMPARISON_REPORT,
    );
  };

  const deleteReport = async (report: {
    id: number;
    type: CreativeReportType;
  }) => {
    const router = useRouter();
    const { notify } = useNotifications();
    const store =
      report.type === "topcreatives"
        ? useCreativeReportingStore()
        : useCreativeComparisonStore();

    const confirmed = confirm(
      "Are you sure you want to delete this report? This action cannot be undone.",
    );

    if (confirmed) {
      const errorMaybe = await store.deleteReport(report.id);
      notify(errorMaybe, "Report deleted successfully.");
      await router.push("/");
    }
  };

  const createSnapshot = async (dto: {
    reportId: number;
    reportType: CreativeReportType;
    pageSize: number;
    pageNumber: number;
    provider: Provider;
  }) => {
    const config = useRuntimeConfig();
    const { track, AnalyticsEvents } = useSegment();
    const store =
      dto.reportType === "topcreatives"
        ? useCreativeReportingStore()
        : useCreativeComparisonStore();
    const { notify } = useNotifications();
    const errorOrSnapshot = await store.createSnapshot(dto);
    if (typeof errorOrSnapshot === "string") {
      notify(errorOrSnapshot, "Snapshot created successfully.");
    } else if (errorOrSnapshot == null) {
      notify("Something went wrong", "");
    } else {
      notify(null, "Snapshot created successfully.");
      track(
        dto.reportType === "topcreatives"
          ? AnalyticsEvents.SNAPSHOT_TOPCREATIVE_REPORT
          : AnalyticsEvents.SNAPSHOT_COMPARISON_REPORT,
      );
      const slug =
        dto.reportType === "topcreatives"
          ? "creative-reporting"
          : "creative-comparison";
      const url =
        config.public.baseUrl +
        "/" +
        slug +
        "/snapshot/" +
        errorOrSnapshot.uuid +
        "?provider=" +
        dto.provider +
        // Don't add page number as in snapshots we are always on the first page
        "&pageSize=" +
        dto.pageSize;

      await navigateTo(url, { external: true, open: { target: "_blank" } });
    }
  };

  const groupsToCreatives = (
    groups: AnyCreativeReportGroup[],
  ): Array<Creative> => {
    const { getEmptyCreative, getMappedCreativeInfo } = useCreatives();
    return groups.map((g, idx) => {
      const creative =
        Array.isArray(g.creatives) && g.creatives.length > 0
          ? g.creatives[0]
          : null;
      const info =
        creative != null
          ? getMappedCreativeInfo([g.creatives[0].info])[0]
          : getEmptyCreative({ idx }).info;
      const metrics = [...g.info.metrics];
      const primaryMetric = g.info.primaryMetric;

      return {
        ...g.creatives[0],
        info: {
          ...info,
          primaryMetric,
          metrics, // Overwrite metrics to keep position info from group
        },
      };
    });
  };

  const sortTableColumns = <T extends { metricName?: string | undefined }>(
    columns: T[],
    templateMetrics: string[],
  ) => {
    const sortedColumns = [];

    for (const metric of templateMetrics) {
      const column = columns.find((c) => c.metricName === metric);
      if (column != null) {
        sortedColumns.push(column);
      }
    }

    return sortedColumns;
  };

  return {
    getReportDetailPage,
    getReportId,
    getReportKey,
    getReportByKey,
    getReportDuplicatePage,
    copyShareLink,
    deleteReport,
    createReport,
    groupsToCreatives,
    sortTableColumns,
    getReportType,
    createSnapshot,
  };
};
